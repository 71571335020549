import { defineStore } from 'pinia'
import type { Case } from '@/types/case'

export const useCaseStore = defineStore('case', () => {
  const id = ref<string>()
  const reportedAt = ref<Date | null>()

  /** Load a new case */
  function loadCase(newCase: Case) {
    id.value = newCase.id
    reportedAt.value = newCase.reportedAt
  }

  return {
    id,
    reportedAt,
    loadCase,
  }
})
export type CaseStore = ReturnType<typeof useCaseStore>
