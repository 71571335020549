import { z } from 'zod'

export const Rating = z.enum([
  'VeryBad',
  'Bad',
  'Good',
  'VeryGood',
  'ExtremelyGood',
])

export const Placement = z.enum(['Inside', 'Outside'])

export const Recommendation = z.enum(['CanBeRepaired', 'ShouldBeReplaced'])

export const DamageSeverity = z.enum([
  'Critical',
  'Serious',
  'Minor',
  'Possible',
])
export const IssueSeverity = z.enum([
  'RiskOfFire',
  'RiskOfElectrocution',
  'IllegalInstallation',
  'NeedsInvestigation',
])

export const Price = z.object({
  materialPrice: z.number(),
  workPrice: z.number(),
})

export const FileTypeEnum = z.enum(['Image', 'Video'])

// `Image` and `Video` are custom schemas used to compose `Asset` as a discriminated union
export const Image = z.object({
  id: z.string().uuid(),
  filePath: z.string().url(),
  fileType: z.literal('Image'),
  xMarker: z.number(),
  yMarker: z.number(),
  width: z.number().int(),
  height: z.number().int(),
})
export const Video = z.object({
  id: z.string().uuid(),
  filePath: z.string().url(),
  fileType: z.literal('Video'),
})
export const Asset = z.discriminatedUnion('fileType', [Image, Video])

export const InspectionDate = z.string().pipe(z.coerce.date())

// Condition report
export const Damage = z.object({
  id: z.string().uuid(),
  group: Placement,
  suggested: z.boolean(),
  buildingPart: z.string(),
  title: z.string(),
  placement: z.string(),
  craftsman: z.nullable(z.string()),
  recommendation: Recommendation,
  severity: DamageSeverity,
  price: z.nullable(Price),
  assets: z.array(Asset),
})

export const ConditionReport = z.object({
  id: z.string().uuid(),
  serialNumber: z.string(),
  inspectionDate: InspectionDate,
  overallRating: Rating,
  damages: z.nullable(z.array(Damage)),
})

// Electricity report
export const Issue = z.object({
  id: z.string().uuid(),
  group: Placement,
  suggested: z.boolean(),
  title: z.string(),
  placement: z.string(),
  recommendation: Recommendation,
  severity: IssueSeverity,
  price: z.nullable(Price),
  assets: z.array(Asset),
})

export const ElectricityReport = z.object({
  id: z.string().uuid(),
  serialNumber: z.string(),
  inspectionDate: InspectionDate,
  overallRating: Rating,
  issues: z.nullable(z.array(Issue)),
})

// Potential

export const EconomicalQualityName = z.enum([
  'OuterWalls',
  'Foundation',
  'Roof',
  'OuterWoodwork',
  'WindowsAndDoors',
])
export const State = z.enum(['BelowAverage', 'Average', 'AboveAverage'])
export const RemainingLIfeSpan = z.enum(['ZeroToFive', 'FiveToTen', 'OverTen'])

export const EconomicalQuality = z.object({
  id: z.string().uuid(),
  name: EconomicalQualityName,
  state: State,
  remainingLifeSpan: RemainingLIfeSpan,
})

export const SocialQualityType = z.enum([
  'Light',
  'Ventilation',
  'Acoustics',
  'Sound',
])

export const SocialQuality = z.object({
  id: z.string().uuid(),
  type: SocialQualityType,
  status: State,
})

// Property

export const Address = z.object({
  address1: z.string(),
  address2: z.nullable(z.string()),
  postalCode: z.string(),
  city: z.string(),
})

export const PropertyType = z.enum([
  'Enfamilieshus',
  'Flerfamilieshus',
  'Fritidshus',
  'Byhus',
  'Dobbelthus',
  'Ejerlejlighed',
  'IdeelAnpart',
  'Kædehus',
  'Parcelhus',
  'Rækkehus',
  'Sommerhus',
  'Stuehus',
])

export const Source = z.enum(['Ee', 'Tr', 'Em'])

export const Building = z.object({
  id: z.string().uuid(),
  name: z.string(),
  mainBuilding: z.boolean(),
  source: Source,
  constructionYear: z.number().int(),
})

export const Property = z.object({
  id: z.string().uuid(),
  address: Address,
  type: PropertyType,
  buildings: z.array(Building),
  conditionReport: ConditionReport,
  electricityReport: z.nullable(ElectricityReport),
  economicalQualities: z.array(EconomicalQuality),
  socialQualities: z.array(SocialQuality),
})

export const EnergyConsumption = z.object({
  id: z.string().uuid(),
  status: z.nullable(z.number()),
  profitable: z.nullable(z.number()),
})

export const Co2Emission = z.object({
  id: z.string().uuid(),
  status: z.nullable(z.number()),
  profitable: z.nullable(z.number()),
})

// Energy label
export const EnergyLabelType = z.enum(['Status', 'Profitable'])
export const EnergyLabelRating = z.enum([
  'A1',
  'A2',
  'A2010',
  'A2015',
  'A2020',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
])

export const EnergyLabel = z.object({
  id: z.string().uuid(),
  // Parse the URL, or return `null` if the URL is invalid
  icon: z.string(),
  energyLabelType: EnergyLabelType,
  name: EnergyLabelRating,
})

export const suggestionType = z.enum([
  'Profitable',
  'ProfitableWhenRenovating',
  'Other',
])

export const Suggestion = z.object({
  id: z.string().uuid(),
  radId: z.number(),
  title: z.string(),
  description: z.string(),
  isRecommended: z.boolean(),
  classification: z.string(),
  buildingPart: z.nullable(z.string()),
  investment: z.number(),
  lifespan: z.string(),
  price: z.nullable(z.number()),
  annualSavings: z.number(),
  annualCo2Savings: z.number(),
  category: suggestionType,
  potentialEnergyLabel: z.string(),
})

// Case
export const Case = z.object({
  id: z.string().uuid(),
  energyLabelId: z.nullable(z.number().int()),
  property: Property,
  energyConsumption: z.nullable(EnergyConsumption),
  co2Emission: z.nullable(Co2Emission),
  energyLabels: z.nullable(z.array(EnergyLabel)),
  suggestionGroups: z.nullable(z.array(Suggestion)),
  reportedAt: z.nullable(z.string().pipe(z.coerce.date())),
})
